import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseAlertComponent, FuseAlertType } from '@fuse/components/alert';
import { FormsModule, NgForm, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import * as actions from 'app/store/actions';

@Component({
  selector: 'app-pau-number',
  standalone: true,
  imports: [
    CommonModule,
    FuseAlertComponent, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatButtonModule, MatProgressSpinnerModule, MatDialogModule, MatIconModule
  ],

  templateUrl: './pau-number.component.html',
  styleUrls: ['./pau-number.component.scss']
})
export class PauNumberComponent implements OnInit {
  // @ViewChild('comingSoonNgForm') comingSoonNgForm: NgForm;

  alert: { type: FuseAlertType; message: string } = {
    type: 'success',
    message: '',
  };
  comingSoonForm: UntypedFormGroup;
  showAlert: boolean = false;
  pauNumber: any = null
  pauCorrectNumber: boolean = true;
  pauDescripcion: any = null

  role: any
  /**
   * Constructor
   */
  constructor(

    private _formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private dialogRef: MatDialogRef<PauNumberComponent>
  ) {
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true
    this.store.select('auth').subscribe(data => {
      this.role = data.currentUser?.role
      this.pauNumber = data.pauNumber
      this.pauDescripcion = data.pauDescription
    })
    this.comingSoonForm = this._formBuilder.group({
      pau: [this.pauNumber, [Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^[0-9]*$')]],
      descripcion_pau: [this.pauDescripcion, [Validators.required, Validators.minLength(3), Validators.maxLength(240)]]
    });

  }
  savePauNumber() {
    const pauNumber = this.comingSoonForm.controls['pau'].value;
    this.store.dispatch(new actions.SetPauAction(pauNumber));
    this.store.dispatch(new actions.SetPauDescriptionAction(this.comingSoonForm.controls['descripcion_pau'].value));
    this.dialogRef.close();
  }
}
