
import * as fromRecipes from '../actions/recipes.actions';

export interface RecipesState{
    filters:any;
    recipeList:any[];
    selectedRecipeId:any; 
    selectedRecipeData:any;
    recipeStatusSummay:any[];

}

const initialState: RecipesState={
    filters:null,
    recipeList:[],
    selectedRecipeId:null,
    selectedRecipeData:null,
    recipeStatusSummay: null

};

export function recipesReducer (state=initialState,action:fromRecipes.recipesActions): RecipesState{
    switch (action.type) {

        case fromRecipes.SET_RECIPE_FILTERS:
            return{ 
                ...state,
                filters: {...action.payload}
            };
        case fromRecipes.UNSET_RECIPE_FILTERS:
            return {
                ...state,
                filters: null
            };

        case fromRecipes.SET_SELECTED_RECIPE_ID:
            return{ 
                ...state,
                selectedRecipeId: action.payload
            };
        case fromRecipes.UNSET_SELECTED_RECIPE_ID:
            return {
                ...state,
                selectedRecipeId: null
            };
    
        case fromRecipes.SET_SELECTED_RECIPE_DATA:
            return{ 
                ...state,
                selectedRecipeData: {...action.payload}
            };
        case fromRecipes.UNSET_SELECTED_RECIPE_DATA:
            return {
                ...state,
                selectedRecipeData: null
            };

        case fromRecipes.SET_RECIPE_LIST:
            return{ 
                ...state,
                recipeList: [...action.payload]
            };
        case fromRecipes.UNSET_RECIPE_LIST:
            return {
                ...state,
                recipeList: []
            };

        case fromRecipes.SET_RECIPE_STATUS_SUMMARY:
            return{ 
                ...state,
                recipeStatusSummay: [...action.payload]
            };
        case fromRecipes.UNSET_RECIPE_STATUS_SUMMARY:
            return {
                ...state,
                recipeStatusSummay: null
            };

        default:
            return state;
    }
}

