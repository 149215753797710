import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { pauInterceptor } from './pau.interceptor';
import { PauService } from './pau.service';

export const providePau = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        provideHttpClient(withInterceptors([pauInterceptor])),
        {
            provide : ENVIRONMENT_INITIALIZER,
            useValue: () => inject(PauService),
            multi   : true,
        },
    ];
};
