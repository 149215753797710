
    <button
        mat-stroked-button
        (click)="openPauNumber()">

        <mat-icon [svgIcon]="'heroicons_outline:magnifying-glass-circle'"></mat-icon>
        <div class="tracking-tight leading-6 truncate">

            <span class="p-2"> Editar número PAU</span>
            <span class="text-red-500">{{pauNumber }}</span> 
        </div>
    </button>

