import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FuseAlertComponent } from '@fuse/components/alert';
import { AdministrationUserService } from 'app/services/administration-user.service';
import { DialogsService } from 'app/services/dialogs.service';
import { update } from 'lodash';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-administration-user-password-dialog',
  templateUrl: `./administration-user-password-dialog.component.html`,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    CommonModule,
    ReactiveFormsModule,
    FuseAlertComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdministrationUserPasswordDialogComponent {
  userPasswordForm: FormGroup;
  personalPasswordForm: FormGroup;
  isPersonalPassword = false;
  characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
  copiedToClipboard = false;

  constructor(
    private dialogsSvc: DialogsService,
    public dialogRef: MatDialogRef<AdministrationUserPasswordDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      password: string;
      isPersonalPassword: boolean;
      userId?: number;
      email?: string;
    },
    private administrationUserService: AdministrationUserService
  ) {
    this.isPersonalPassword = data.isPersonalPassword;
    this.personalPasswordForm = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        newPassword: ['', [Validators.required, Validators.minLength(4)]],
        confirmNewPassword: [
          '',
          Validators.required,
          this.confirmPasswordValidator,
        ],
      },
      {
        updateOn: 'change',
      }
    );

    this.userPasswordForm = this.fb.group(
      {
        passwordField: [
          '',
          { validators: [Validators.required, Validators.minLength(4)] },
        ],
      },
      { updateOn: 'change', validators: this.passwordMatchValidator }
    );
    this.personalPasswordForm.valueChanges.subscribe(() => {
      Object.keys(this.personalPasswordForm.controls).forEach((field) => {
        const control = this.personalPasswordForm.get(field);
        if (control) {
          control.markAsTouched({ onlySelf: true });
        }
      });
      this.personalPasswordForm
        .get('newPassword')
        .valueChanges.subscribe(() => {
          this.personalPasswordForm
            .get('confirmNewPassword')
            .updateValueAndValidity();
        });
    });
  }

  confirmPasswordValidator(
    control: AbstractControl
  ): Observable<ValidationErrors | null> {
    return new Observable((observer) => {
      const newPassword = control.parent?.get('newPassword')?.value;
      const confirmNewPassword = control.value;

      if (newPassword !== confirmNewPassword) {
        observer.next({ mismatch: true });
      } else {
        observer.next(null);
      }

      observer.complete();
    });
  }

  passwordMatchValidator(form: FormGroup): { [key: string]: boolean } | null {
    const newPassword = form.get('newPassword');
    const confirmNewPassword = form.get('confirmNewPassword');

    if (
      newPassword &&
      confirmNewPassword &&
      newPassword.value !== confirmNewPassword.value
    ) {
      return { mismatch: true };
    }
    return null;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  generatePassword(): void {
    let password = '';
    this.copiedToClipboard = false;
    for (let i = 0; i < 12; i++) {
      password += this.characters.charAt(
        Math.floor(Math.random() * this.characters.length)
      );
    }
    this.userPasswordForm.get('passwordField').setValue(password);
  }

  copyToClipboard(password: string) {
    this.copiedToClipboard = true;
    navigator.clipboard.writeText(password);
    setTimeout(() => {
      this.copiedToClipboard = false;
    }, 2000);
  }

  onPasswordFieldChange(): void {
    if (!this.userPasswordForm.get('passwordField').value) {
      this.copiedToClipboard = false;
    }
  }

  async onSaveClick(): Promise<void> {
    if (this.isPersonalPassword) {
      if (this.personalPasswordForm.valid) {
        const email = this.data.email;
        const newPassword = this.personalPasswordForm.get('newPassword').value;
        const oldPassword =
          this.personalPasswordForm.get('currentPassword').value;
        await this.administrationUserService
          .updatePersonalPasswordPromise(
            email,
            btoa(newPassword),
            btoa(oldPassword)
          )
          .then(() => {
            this.dialogsSvc.success(
              'Cambio de contraseña exitoso',
              'La contraseña ha sido actualizada'
            );
          })
          .catch((err) => {
            console.log(err);
            this.dialogsSvc.error(
              'Error al cambiar la contraseña',
              `${err.message} <br> ${err.response}`
            );
          });
        return this.dialogRef.close();
      }
    } else {
      if (this.userPasswordForm.valid) {
        await this.administrationUserService
          .updateUserPasswordPromise(
            this.data.userId,
            btoa(this.userPasswordForm.value.passwordField)
          )
          .then(() => {
            this.dialogsSvc.success(
              'Cambio de contraseña exitoso',
              'La contraseña ha sido actualizada'
            );
          })
          .catch((err) => {
            this.dialogsSvc.error(
              'Error al cambiar la contraseña',
              `${err.message} <br> ${err.response}`
            );
          });
        return this.dialogRef.close();
      }
    }
  }
}
