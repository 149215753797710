import { User } from 'app/models/user.model';
import * as fromUserList from '../actions/user.actions';

export interface UserListState {
  userList: {
    id: string;
    name: string;
    email: string;
    roles: string[];
    active: boolean;
  }[];
}

const initialState: UserListState = {
  userList: [],
};

export function userListReducer(
  state = initialState,
  action: fromUserList.UserListActions
): UserListState {
  switch (action.type) {
    case fromUserList.SET_USER_LIST_DATA:
      return {
        ...state,
        userList: [...action.payload],
      };
    case fromUserList.SET_USER_ACTIVE_STATE:
      return {
        ...state,
        userList: state.userList.map((user) => {
          if (user.id === action.payload.id) {
            return { ...user, active: !user.active };
          }
          return user;
        }),
      };
    default:
      return state;
  }
}
