import { Action } from "@ngrx/store";

export const SET_REJECTION = '[Rejections] Set Rejection';
export const UNSET_REJECTION = '[Rejections] Unset Rejection';


export const SET_REJECTION_FILTERS = '[Rejections] Set Rejection Filters';
export const UNSET_REJECTION_FILTERS = '[Rejections] Unset Rejection Filters';

export const SET_REJECTION_LIST = '[Rejections] Set Rejection List';
export const UNSET_REJECTION_LIST = '[Rejections] Unset Rejection List';

export const SET_REJECTION_STATUS_SUMMARY = '[Rejections] Set Rejection Status Summary';
export const UNSET_REJECTION_STATUS_SUMMARY = '[Rejections] Unset Rejection Status Summary';


export const SET_SELECTED_REJECTION = '[Rejections] Set Selected Rejection';
export const UNSET_SELECTED_REJECTION = '[Rejections] Unset Selected Rejection';


export const SET_SELECTED_REJECTION_DATA = '[Rejections] Set Selected Rejection Data';
export const UNSET_SELECTED_REJECTION_DATA = '[Rejections] Unset Selected Rejection Data';



export class SetRejectionAction implements Action {
    constructor(public payload: any) { }
    readonly type = SET_REJECTION;
}

export class UnsetRejectionAction implements Action {
    readonly type = UNSET_REJECTION;
}

export class SetRejectionFiltersAction implements Action {
    constructor(public payload: any) { }
    readonly type = SET_REJECTION_FILTERS;
}

export class UnsetRejectionFiltersAction implements Action {
    readonly type = UNSET_REJECTION_FILTERS;
}

export class SetRejectionListAction implements Action {
    constructor(public payload: any) { }
    readonly type = SET_REJECTION_LIST;
}

export class UnsetRejectionListAction implements Action {
    readonly type = UNSET_REJECTION_LIST;
}


export class SetSelectedRejectionAction implements Action {
    constructor(public payload: any) { }
    readonly type = SET_SELECTED_REJECTION;
}

export class UnsetSelectedRejectionAction implements Action {
    readonly type = UNSET_SELECTED_REJECTION;
}

export class SetSelectedRejectionDataAction implements Action {
    constructor(public payload: any) { }
    readonly type = SET_SELECTED_REJECTION_DATA;
}

export class UnsetSelectedRejectionDataAction implements Action {
    readonly type = UNSET_SELECTED_REJECTION_DATA;
}


export type rejectionsActions =
    SetRejectionAction |
    UnsetRejectionAction |
    SetRejectionFiltersAction |
    UnsetRejectionFiltersAction |
    SetRejectionListAction |
    UnsetRejectionListAction |
    SetSelectedRejectionAction |
    UnsetSelectedRejectionAction |
    SetSelectedRejectionDataAction |
    UnsetSelectedRejectionDataAction ;

