import { Action } from '@ngrx/store';

export const SET_USER_LIST_DATA = '[UserList] Set User List Data';

export class SetUserListData implements Action {
  constructor(public payload: any) {}
  readonly type = SET_USER_LIST_DATA;
}

export const SET_USER_ACTIVE_STATE = '[UserList] Set User Active State';

export class SetUserActiveState implements Action {
  constructor(public payload: any) {}
  readonly type = SET_USER_ACTIVE_STATE;
}

export type UserListActions = SetUserListData | SetUserActiveState;
