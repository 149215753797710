let environment = {
  production: false,
  url: 'https://qtool.dev.digilacca.ar/qtool',
  // url: 'http://192.168.48.143:8082/qtool',
  roles: [
    { level: 1, description: 'CAP', display: 'CAP' },
    { level: 2, description: 'CAD', display: 'CAD' },
    { level: 3, description: 'CAT', display: 'CAT' },
    { level: 4, description: 'LME', display: 'LME' },
    { level: 5, description: 'LES', display: 'LES' },
    { level: 6, description: 'VAL', display: 'VAL' },
  ],
  //url: '/api'
};

export { environment };
