import { Component } from '@angular/core';
import { CommonModule, SlicePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store/app.reducer';
import { MatDialog } from '@angular/material/dialog';
import { PauNumberComponent } from 'app/modules/auth/pau-number/pau-number.component';

@Component({
  selector: 'app-edit-pau-number',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,MatButtonModule
  ],
  templateUrl: './edit-pau-number.component.html',
  styleUrls: ['./edit-pau-number.component.scss']
})
export class EditPauNumberComponent {
  pauNumber:any=null
  pauDescription:any=null
  constructor(
    private store:Store<AppState>,
    private dialog:MatDialog

  ){}

  ngOnInit(): void
  {

    this.store.select('auth').subscribe(data=>{
      this.pauNumber=data.pauNumber
      this.pauDescription=data.pauDescription
    })
  }

  openPauNumber(){
    this.dialog.open(PauNumberComponent)
}
}
