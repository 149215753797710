import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { environment } from '../../../src/environments/environment';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class AdministrationUserService {
  constructor(private http: HttpClient, private authSvc: AuthService) {}

  // PROMISES
  createUserPromise({ name, email, password, roles }): Promise<any> {
    const url = `${environment.url}/admin-panel/users`;
    const body = {
      name,
      email,
      password,
      roles: [{ description: roles.description }],
    };
    console.log(JSON.stringify(body));
    return new Promise<any | void>((resolve, reject) => {
      this.http
        .post(url, JSON.stringify(body), this.authSvc.headers_http)
        .subscribe({
          next(res: any) {
            resolve(res);
          },
          error(msg) {
            reject(msg);
          },
        });
    });
  }

  getAllUsersPromise(): Promise<any> {
    const url = `${environment.url}/admin-panel/users`;
    return new Promise<any | void>((resolve, reject) => {
      this.http.get(url, this.authSvc.headers_http).subscribe({
        next(res: any) {
          console.log(res);
          resolve(res);
        },
        error(msg) {
          reject(msg);
        },
      });
    });
  }

  updateUserPasswordPromise(id: number, newPassword: string): Promise<any> {
    const url = `${environment.url}/admin-panel/users/${id}/password`;
    const body = {
      newPassword,
    };
    return new Promise<any | void>((resolve, reject) => {
      this.http
        .put(url, JSON.stringify(body), this.authSvc.headers_http)
        .subscribe({
          next(res: any) {
            resolve(res);
          },
          error(msg) {
            reject(msg);
          },
        });
    });
  }

  updatePersonalPasswordPromise(
    email: string,
    newPassword: string,
    oldPassword: string
  ): Promise<any> {
    const url = `${environment.url}/users/newPassword?email=${email}&oldPassword=${oldPassword}&newPassword=${newPassword}`;

    const promise = new Promise<any | void>((resolve, reject) => {
      this.http.put(url, {}, this.authSvc.headers_http).subscribe({
        next(res: any) {
          resolve(res);
        },
        error(msg) {
          reject(msg);
        },
      });
    });
    return promise;
  }

  updateUserRolePromise(id: number, description: string): Promise<any> {
    const url = `${environment.url}/admin-panel/users/${id}/role`;
    const body = {
      description,
    };
    return new Promise<any | void>((resolve, reject) => {
      this.http
        .put(url, JSON.stringify(body), this.authSvc.headers_http)
        .subscribe({
          next(res: any) {
            resolve(res);
          },
          error(msg) {
            reject(msg);
          },
        });
    });
  }

  uploadFile(file: File): Promise<any> {
    const url = `${environment.url}/admin-panel/upload-users`;
    const formData = new FormData();
    formData.append('file', file);

    console.log(formData);
    console.log(file);
    return new Promise<any | void>((resolve, reject) => {
      this.http
        .post(url, formData, this.authSvc.headers_http_FormData)
        .subscribe({
          next(res: any) {
            resolve(res);
          },
          error(msg) {
            reject(msg);
          },
        });
    });
  }

  updateUserActivePromise(id: number): Promise<any> {
    const url = `${environment.url}/admin-panel/users/${id}`;
    return new Promise<any | void>((resolve, reject) => {
      this.http.delete(url, this.authSvc.headers_http).subscribe({
        next(res: any) {
          resolve(res);
        },
        error(msg) {
          reject(msg);
        },
      });
    });
  }

  generateExcel() {
    const data = [['Nombre', 'Apellido', 'Sector', 'Mail', 'Password']];
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, 'Plantilla-QTOOL');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}.xlsx`);
  }
}
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
