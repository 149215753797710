import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import * as actions from 'app/store/actions';

@Injectable({
  providedIn: 'root'
})
export class PersistenceService {

  constructor(
    private store:Store<AppState>
  ) { }

  setSession(key: string, data: any) {
    try {
      localStorage.setItem(key, data);
    } catch (e) {
      console.log('Error en storage: ', e);
    }
  }

  getSession(key: string) {

    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.log('Error en storage: ', e);
      return;
    }
  }

  setUser(user: any) {
    this.removeUser();
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('role', user.role);
  }

  getUser(): any{
    let userResponse:any;
    let user = localStorage.getItem('user');
    if (user!= null) {
      userResponse = JSON.parse(user);
    }
    return userResponse;
  }

  removeUser() {
    localStorage.removeItem('user');
    localStorage.removeItem('role');
  }


  //hidratate store (call app.component)
  hidratateStore(){

    //currentUser data persist (RESTAURANT-PROVIDER)
    if(this.getSession('user')){
      this.store.dispatch( new actions.SetUserAction(
          this.getUser()
      ));
    }

  }

   //clean session storage
  remove_localStorage(){
    this.removeUser();
  }
}
