import { Action } from "@ngrx/store";

export const SET_USER = '[Auth] Set User';
export const UNSET_USER = '[Auth] Unset User';

export const SET_PAU = '[Auth] Set PAU';
export const UNSET_PAU = '[Auth] Unset PAU';

export const SET_PAU_DESCRIPTION = '[Auth] Set PAU Description';
export const UNSET_PAU_DESCRIPTION = '[Auth] Unset PAU Description';

export class SetUserAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_USER;
}

export class UnsetUserAction implements Action {
    readonly type = UNSET_USER;
}

export class SetPauAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_PAU;
}

export class UnsetPauAction implements Action {
    readonly type = UNSET_PAU;
}

export class SetPauDescriptionAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_PAU_DESCRIPTION;
}

export class UnsetPauDescriptionAction implements Action {
    readonly type = UNSET_PAU_DESCRIPTION;
}


export type authActions=    SetUserAction  |
                            UnsetUserAction |
                            SetPauAction    |
                            UnsetPauAction|
                            SetPauDescriptionAction|
                            UnsetPauDescriptionAction;
                           
