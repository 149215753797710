import { Action } from "@ngrx/store";

export const SET_RECIPE_FILTERS = '[Recipes] Set recipe FILTERS';
export const UNSET_RECIPE_FILTERS = '[Recipes] Unset recipe FILTERS';

export const SET_SELECTED_RECIPE_ID = '[Recipes] Set selected recipe ID';
export const UNSET_SELECTED_RECIPE_ID = '[Recipes] Unset selected recipe ID';

export const SET_SELECTED_RECIPE_DATA = '[Recipes] Set selected recipe DATA';
export const UNSET_SELECTED_RECIPE_DATA = '[Recipes] Unset selected recipe DATA';

export const SET_RECIPE_LIST = '[Recipes] Set recipe list';
export const UNSET_RECIPE_LIST = '[Recipes] Unset recipe list';

export const SET_RECIPE_STATUS_SUMMARY= '[Recipes] Set STATUS_SUMMARY';
export const UNSET_RECIPE_STATUS_SUMMARY = '[Recipes] Unset STATUS_SUMMARY';

export class SetRecipeFiltersAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_RECIPE_FILTERS;
}

export class UnsetRecipeFiltersAction implements Action {
    readonly type = UNSET_RECIPE_FILTERS;
}

export class SetSelectedRecipeIdAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_SELECTED_RECIPE_ID;
}

export class UnsetSelectedRecipeIdAction implements Action {
    readonly type = UNSET_SELECTED_RECIPE_ID;
}

export class SetSelectedRecipeDataAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_SELECTED_RECIPE_DATA;
}

export class UnsetSelectedRecipeDataAction implements Action {
    readonly type = UNSET_SELECTED_RECIPE_DATA;
}

export class SetRecipeListAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_RECIPE_LIST;
}

export class UnsetRecipeListAction implements Action {
    readonly type = UNSET_RECIPE_LIST;
}

export class SetRecipeStatusSummayAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_RECIPE_STATUS_SUMMARY;
}

export class UnsetRecipeStatusSummayAction implements Action {
    readonly type = UNSET_RECIPE_STATUS_SUMMARY;
}


export type recipesActions=     SetSelectedRecipeIdAction       |
                                UnsetSelectedRecipeIdAction     |
                                SetSelectedRecipeDataAction     |
                                UnsetSelectedRecipeDataAction   |
                                SetRecipeListAction             |
                                UnsetRecipeListAction           |
                                SetRecipeFiltersAction          |
                                UnsetRecipeFiltersAction        |
                                SetRecipeStatusSummayAction     |
                                UnsetRecipeStatusSummayAction
                                ;
                           
