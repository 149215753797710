import { Injectable } from "@angular/core";
import { Actions, createEffect,  ofType } from "@ngrx/effects";
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

import * as authActions from '../actions';

import { Store } from "@ngrx/store";
import { AppState } from "../app.reducer";
import { PersistenceService } from "app/services/persistence.service";



@Injectable()
export class AuthEffects {

    constructor(
        private actions$: Actions,
        private store:Store<AppState>,
        private persistenceSvc:PersistenceService
    ) {   }

    setUser$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(authActions.SET_USER),
            switchMap((action: authActions.SetUserAction) => {
    
                return of(this.persistenceSvc.setUser(action.payload));

              })

        )

    },
    {dispatch:false}
    );

    unSetUser$ = createEffect(():any=>{

        return this.actions$.pipe(
            ofType(authActions.UNSET_USER),
            switchMap((action: authActions.UnsetUserAction) => {
                console.log('entra efecto=>>>>')
                this.store.dispatch(new authActions.UnsetPauAction())
                this.store.dispatch(new authActions.UnsetPauDescriptionAction())
                return of(this.persistenceSvc.removeUser());

              })

        )

    },
    {dispatch:false}
    );


}
