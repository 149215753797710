import { Component, OnDestroy, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PersistenceService } from './services/persistence.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet],
})
export class AppComponent implements OnInit,OnDestroy
{
    /**
     * Constructor
     */
    constructor(
    private persistenceSvc:PersistenceService
    )
    {
    }

    ngOnInit(): void {
        this.persistenceSvc.hidratateStore();

    }

    ngOnDestroy(): void {
        this.persistenceSvc.remove_localStorage();


    }
}


