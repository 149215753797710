import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "app/store/app.reducer";
import { Observable, of } from "rxjs";
@Injectable({ providedIn: 'root' })

export class PauService {
    constructor(
        private store: Store<AppState>

    ) {

    }

    getPauNumber() {
        let pauNumber;
        this.store.select('auth').subscribe(data => {
            pauNumber = data.pauNumber
        });
        return pauNumber
    }

    getPauDescription() {
        let pauDescription;
        this.store.select('auth').subscribe(data => {
            pauDescription = btoa(data.pauDescription);
        });

        return pauDescription;
    }

    get pauNumber(): boolean {
        let pauNumber = false;
        this.store.select('auth').subscribe(authData => {
            if (authData.pauNumber) {
                pauNumber = true;
            } else {
                pauNumber = false;
            }
        });

        return pauNumber;
    }

    checkPau(): Observable<boolean> {
        let pauNumber;
        let role;
        this.store.select('auth').subscribe(data => {
            pauNumber = data.pauNumber
            role = data?.currentUser?.role
        });
        // Check if the user is a provider

        if (role >= 9) {
            return of(true);

        } else {
            if (pauNumber) {
                return of(true);

            } else {

                return of(false);
            }
        }
    }

}
