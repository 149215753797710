import { AuthEffects } from './auth.effects';




export const effectsArr:any[]=
    [
        
        AuthEffects,
                               
    ];

export * from './auth.effects';

