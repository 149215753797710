<div mat-dialog-content>

    <div class="flex flex-col flex-auto items-center sm:justify-center min-w-0">
        <div class="w-full sm:w-auto ">
            <div class="w-full max-w-80 sm:w-80 mx-auto sm:mx-0">
                <!-- Logo -->
                <div class="w-12">
                    <mat-icon class="icon-size-18" [svgIcon]="'heroicons_outline:magnifying-glass-circle'"></mat-icon>
                </div>

                <!-- Title -->
                <div class="mt-8 text-3xl font-extrabold tracking-tight leading-tight">Seguimiento de consultas</div>
                <div class="mt-0.5">
                    Por favor ingrese el número PAU para realizar el seguimiento de sus consultas!
                </div>

                <!-- Alert -->
                <fuse-alert class="mt-8" *ngIf="showAlert" [appearance]="'outline'" [showIcon]="false"
                    [type]="alert.type" [@shake]="alert.type === 'error'">
                    {{alert.message}}
                </fuse-alert>

                <!-- Coming soon form -->
                <form class="mt-8" [formGroup]="comingSoonForm" #comingSoonNgForm="ngForm">

                    <!-- Email field -->
                    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                        <mat-label>Número PAU</mat-label>
                        <input id="pau" matInput [formControlName]="'pau'">
                        <mat-error *ngIf="comingSoonForm.get('pau').hasError('required')">
                            Debe ser un número de 6 dígitos.
                        </mat-error>

                    </mat-form-field>
                    <mat-form-field class="w-full mt-5" [subscriptSizing]="'dynamic'">
                        <mat-label>Descripción</mat-label>
                        <input id="descripcion_pau" matInput [formControlName]="'descripcion_pau'">
                        <mat-error *ngIf="comingSoonForm.get('descripcion_pau').hasError('required')">
                            Debe colocar una descripción de la tarea.
                        </mat-error>

                    </mat-form-field>

                    <!-- Submit button -->
                    <button class="fuse-mat-button-large w-full mt-3" mat-flat-button type="button" [color]="'primary'"
                        [disabled]="comingSoonForm.invalid" (click)="savePauNumber()">
                        <span *ngIf="!comingSoonForm.disabled">
                            Guardar
                        </span>

                    </button>

                    <div class="mt-5">
                        <mat-label>(*) Campo obligatorio</mat-label>
                    </div>
                </form>
            </div>
        </div>
    </div>


</div>

<div mat-dialog-actions class="flex justify-end">
    <button mat-button mat-dialog-close
        class="mat-mdc-tooltip-trigger mdc-button mat-mdc-button mat-warn mat-mdc-button-base"
        [disabled]="role<9 ? true : false">
        Cerrar
    </button>


</div>