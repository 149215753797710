
import * as fromMedications from '../actions/medications.actions';

export interface MedicationsState{
    filters:any;
    medicationList:any[];
    selectedMedicationId:any; 
    selectedMedicationData:any;

}

const initialState: MedicationsState={
    filters:null,
    medicationList:[],
    selectedMedicationId:null,
    selectedMedicationData:null,
   

};

export function medicationsReducer (state=initialState,action:fromMedications.medicationsActions): MedicationsState{
    switch (action.type) {

        case fromMedications.SET_MEDICATION_FILTERS:
            return{ 
                ...state,
                filters: {...action.payload}
            };
        case fromMedications.UNSET_MEDICATION_FILTERS:
            return {
                ...state,
                filters: null
            };

        case fromMedications.SET_SELECTED_MEDICATION_ID:
            return{ 
                ...state,
                selectedMedicationId: action.payload
            };
        case fromMedications.UNSET_SELECTED_MEDICATION_ID:
            return {
                ...state,
                selectedMedicationId: null
            };
    
        case fromMedications.SET_SELECTED_MEDICATION_DATA:
            return{ 
                ...state,
                selectedMedicationData: {...action.payload}
            };
        case fromMedications.UNSET_SELECTED_MEDICATION_DATA:
            return {
                ...state,
                selectedMedicationData: null
            };

        case fromMedications.SET_MEDICATION_LIST:
            return{ 
                ...state,
                medicationList: [...action.payload]
            };
        case fromMedications.UNSET_MEDICATION_LIST:
            return {
                ...state,
                medicationList: []
            };


        default:
            return state;
    }
}

