import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { PauNumberComponent } from 'app/modules/auth/pau-number/pau-number.component';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  constructor(
    private _fuseConfirmationService: FuseConfirmationService,
    private matDialog:MatDialog
  ) { }

  success(title:any,msg:any){
    return this._fuseConfirmationService.open({
      "title": title,
      "message": msg,
      "icon": {
        "show": true,
        "name": "heroicons_outline:check",
        "color": "success"
      },
      "actions": {
        "confirm": {
          "show": true,
          "label": "Aceptar",
          "color":"primary"
        },
        "cancel": {
          "show": false,
          "label": "Cancelar"
        }
      },
      "dismissible": true
    });
  }

  error(title:any,msg:any){
    return  this._fuseConfirmationService.open({
      "title": title,
      "message": msg,
      "icon": {
        "show": true,
        "name": "heroicons_outline:exclamation-triangle",
        "color": "error"
      },
      "actions": {
        "confirm": {
          "show": true,
          "label": "Aceptar",
          "color": "warn"
        },
        "cancel": {
          "show": false,
          "label": "Cancelar"
        }
      },
      "dismissible": true
    });
  }

  confirm(title:any,msg:any){
    return  this._fuseConfirmationService.open({
      "title": title,
      "message": msg,
      "icon": {
        "show": true,
        "name": "heroicons_outline:question-mark-circle",
        "color": "accent"
      },
      "actions": {
        "confirm": {
          "show": true,
          "label": "Aceptar",
          "color": "primary"
        },
        "cancel": {
          "show": true,
          "label": "Cancelar"
        }
      },
      "dismissible": true
    });
  }

  info(title: any, msg: any) {
    return this._fuseConfirmationService.open({
      "title": title,
      "message": msg,
      "icon": {
        "show": true,
        "name": "heroicons_outline:information-circle",
        "color": "info"
      },
      "actions": {
        "confirm": {
          "show": true,
          "label": "Aceptar",
          "color": "accent"
        },
        "cancel": {
          "show": false,
          "label": "Cancelar"
        }
      },
      "dismissible": true
    });
  }

  openPauDialog(){
    this.matDialog.open(PauNumberComponent)
  }

}
