<h2 mat-dialog-title>Cambiar contraseña</h2>
<mat-dialog-content>
  <p *ngIf="isPersonalPassword">
    Ingresa tu contraseña actual, luego tu nueva contraseña:
  </p>
  <p *ngIf="!isPersonalPassword">Introduce la nueva contraseña:</p>

  <!-- Formulario para cambio de contraseña personal -->
  <form *ngIf="isPersonalPassword" [formGroup]="personalPasswordForm">
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        margin-top: 20px;
      "
    >
      <mat-form-field class="flex-auto">
        <input
          matInput
          type="password"
          placeholder="Contraseña actual"
          formControlName="currentPassword"
          #currentPassword
        />
        <button
          mat-icon-button
          type="button"
          (click)="
            currentPassword.type === 'password'
              ? (currentPassword.type = 'text')
              : (currentPassword.type = 'password')
          "
          matSuffix
        >
          <mat-icon
            class="icon-size-5"
            *ngIf="currentPassword.type === 'password'"
            [svgIcon]="'heroicons_solid:eye'"
          ></mat-icon>
          <mat-icon
            class="icon-size-5"
            *ngIf="currentPassword.type === 'text'"
            [svgIcon]="'heroicons_solid:eye-slash'"
          ></mat-icon>
        </button>
        <mat-icon
          class="icon-size-5"
          matPrefix
          [svgIcon]="'heroicons_solid:key'"
        ></mat-icon>
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <input
          matInput
          type="password"
          placeholder="Nueva contraseña"
          formControlName="newPassword"
          #newPassword
        /><button
          mat-icon-button
          type="button"
          (click)="
            newPassword.type === 'password'
              ? (newPassword.type = 'text')
              : (newPassword.type = 'password')
          "
          matSuffix
        >
          <mat-icon
            class="icon-size-5"
            *ngIf="newPassword.type === 'password'"
            [svgIcon]="'heroicons_solid:eye'"
          ></mat-icon>
          <mat-icon
            class="icon-size-5"
            *ngIf="newPassword.type === 'text'"
            [svgIcon]="'heroicons_solid:eye-slash'"
          ></mat-icon>
        </button>
        <mat-icon
          class="icon-size-5"
          matPrefix
          [svgIcon]="'heroicons_solid:key'"
        ></mat-icon>
        <!-- Errores: solo tiene 4 caracteres -->
        <mat-error> La contraseña debe tener al menos 4 caracteres </mat-error>
      </mat-form-field>
      <mat-form-field class="flex-auto">
        <input
          matInput
          type="password"
          placeholder="Confirmar nueva contraseña"
          formControlName="confirmNewPassword"
          #confirmNewPassword
        />
        <button
          mat-icon-button
          type="button"
          (click)="
            confirmNewPassword.type === 'password'
              ? (confirmNewPassword.type = 'text')
              : (confirmNewPassword.type = 'password')
          "
          matSuffix
        >
          <mat-icon
            class="icon-size-5"
            *ngIf="confirmNewPassword.type === 'password'"
            [svgIcon]="'heroicons_solid:eye'"
          ></mat-icon>
          <mat-icon
            class="icon-size-5"
            *ngIf="confirmNewPassword.type === 'text'"
            [svgIcon]="'heroicons_solid:eye-slash'"
          ></mat-icon>
          <!-- Errores: no coincide con la nueva contraseña -->
        </button>
        <mat-icon
          class="icon-size-5"
          matPrefix
          [svgIcon]="'heroicons_solid:key'"
        ></mat-icon>
        <mat-error
          *ngIf="
            personalPasswordForm.get('confirmNewPassword').invalid &&
            personalPasswordForm.get('confirmNewPassword').touched
          "
        >
          Las contraseñas deben coincidir.
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <form [formGroup]="userPasswordForm" *ngIf="!isPersonalPassword">
    <div
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        margin-top: 20px;
      "
    >
      <mat-label>Nueva contraseña</mat-label>
      <mat-form-field class="flex-auto">
        <input
          matInput
          type="password"
          placeholder="Contraseña"
          formControlName="passwordField"
          (ngModelChange)="onPasswordFieldChange()"
          #passwordField
        />
        <button
          *ngIf="passwordField.value"
          mat-icon-button
          type="button"
          (click)="copyToClipboard(passwordField.value)"
          matSuffix
        >
          <mat-icon
            *ngIf="copiedToClipboard"
            class="icon-size-5"
            [svgIcon]="'heroicons_solid:check'"
          ></mat-icon>
          <mat-icon
            *ngIf="!copiedToClipboard"
            class="icon-size-5"
            [svgIcon]="'heroicons_outline:clipboard'"
          ></mat-icon>
        </button>
        <button
          mat-icon-button
          type="button"
          (click)="
            passwordField.type === 'password'
              ? (passwordField.type = 'text')
              : (passwordField.type = 'password')
          "
          matSuffix
        >
          <mat-icon
            class="icon-size-5"
            *ngIf="passwordField.type === 'password'"
            [svgIcon]="'heroicons_solid:eye'"
          ></mat-icon>
          <mat-icon
            class="icon-size-5"
            *ngIf="passwordField.type === 'text'"
            [svgIcon]="'heroicons_solid:eye-slash'"
          ></mat-icon>
        </button>
        <mat-icon
          class="icon-size-5"
          matPrefix
          [svgIcon]="'heroicons_solid:key'"
        ></mat-icon>
        <mat-error *ngIf="userPasswordForm.get('password')?.invalid"
          >Password is required</mat-error
        >
      </mat-form-field>
      <button
        mat-button
        color="primary"
        style="width: min-content; margin-bottom: 20px"
        (click)="generatePassword()"
      >
        Generar contraseña
      </button>
    </div>
  </form>
  <!-- show warning message to changing the password -->
  <fuse-alert *ngIf="!isPersonalPassword" [type]="'warn'">
    <span fuseAlertTitle>Atención</span>
    Esta acción no puede ser revertida. Asegúrate de informar la nueva
    contraseña al usuario.
  </fuse-alert>
  <fuse-alert *ngIf="isPersonalPassword" [type]="'warn'">
    <span fuseAlertTitle>Atención</span>
    Esta acción no puede ser revertida.
  </fuse-alert>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button
    mat-button
    (click)="onSaveClick()"
    [disabled]="
      isPersonalPassword ? !personalPasswordForm.valid : !userPasswordForm.valid
    "
  >
    Aceptar
  </button>
</mat-dialog-actions>
