import { Action } from "@ngrx/store";

export const SET_MEDICATION_FILTERS = '[Medications] Set medication FILTERS';
export const UNSET_MEDICATION_FILTERS = '[Medications] Unset medication FILTERS';

export const SET_SELECTED_MEDICATION_ID = '[Medications] Set selected medication ID';
export const UNSET_SELECTED_MEDICATION_ID = '[Medications] Unset selected medication ID';

export const SET_SELECTED_MEDICATION_DATA = '[Medications] Set selected medication DATA';
export const UNSET_SELECTED_MEDICATION_DATA = '[Medications] Unset selected medication DATA';

export const SET_MEDICATION_LIST = '[Medications] Set medication list';
export const UNSET_MEDICATION_LIST = '[Medications] Unset medication list';

export const SET_MEDICATION_STATUS_SUMMARY= '[Medications] Set STATUS_SUMMARY';
export const UNSET_MEDICATION_STATUS_SUMMARY = '[Medications] Unset STATUS_SUMMARY';

export class SetMedicationFiltersAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_MEDICATION_FILTERS;
}

export class UnsetMedicationFiltersAction implements Action {
    readonly type = UNSET_MEDICATION_FILTERS;
}

export class SetSelectedMedicationIdAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_SELECTED_MEDICATION_ID;
}

export class UnsetSelectedMedicationIdAction implements Action {
    readonly type = UNSET_SELECTED_MEDICATION_ID;
}

export class SetSelectedMedicationDataAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_SELECTED_MEDICATION_DATA;
}

export class UnsetSelectedMedicationDataAction implements Action {
    readonly type = UNSET_SELECTED_MEDICATION_DATA;
}

export class SetMedicationListAction implements Action {
    constructor( public payload:any){   }
    readonly type=SET_MEDICATION_LIST;
}

export class UnsetMedicationListAction implements Action {
    readonly type = UNSET_MEDICATION_LIST;
}



export type medicationsActions=     SetSelectedMedicationIdAction       |
                                UnsetSelectedMedicationIdAction     |
                                SetSelectedMedicationDataAction     |
                                UnsetSelectedMedicationDataAction   |
                                SetMedicationListAction             |
                                UnsetMedicationListAction           |
                                SetMedicationFiltersAction          |
                                UnsetMedicationFiltersAction        
                                
                                ;
                           
