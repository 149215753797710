
import * as fromAuth from '../actions/auth.actions';

export interface AuthState{

    currentUser:any; //TODO check error when using the User type of the model
    pauNumber:any
    pauDescription:any
}

const initialState: AuthState={
    currentUser:null,
    pauNumber:null,
    pauDescription:null

};

export function authReducer (state=initialState,action:fromAuth.authActions): AuthState{
    switch (action.type) {
        case fromAuth.SET_USER:
            return{
                ...state,
                currentUser: {... action.payload}
            };
        case fromAuth.UNSET_USER:
            return {
                ...state,
                currentUser: null
            };

        case fromAuth.SET_PAU:
            return{
                ...state,
                pauNumber: action.payload
            };
        case fromAuth.UNSET_PAU:
            return {
                ...state,
                pauNumber: null
            };
        case fromAuth.SET_PAU_DESCRIPTION:
            return{
                ...state,
                pauDescription: action.payload
            };
        case fromAuth.UNSET_PAU_DESCRIPTION:
            return {
                ...state,
                pauDescription: null
            };
        default:
            return state;
    }
}

