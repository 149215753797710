import { ActionReducerMap } from '@ngrx/store';

import * as fromReducer from './reducers/';

export interface AppState {
  auth: fromReducer.AuthState;
  recipes: fromReducer.RecipesState;
  medications: fromReducer.MedicationsState;
  rejections: fromReducer.RejectionsState;
  user: fromReducer.UserListState;
}

export const appReducers: ActionReducerMap<any, any> = {
  auth: fromReducer.authReducer,
  recipes: fromReducer.recipesReducer,
  medications: fromReducer.medicationsReducer,
  rejections: fromReducer.rejectionReducer,
  user: fromReducer.userListReducer,
};
